import { createColumnHelper } from '@tanstack/react-table'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { Box } from 'components/layout/box'
import { EarningsDataInterface, EconomicDataInterface } from 'api/right-side-panel/fetchCalendar'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import * as tableStyle from './TableStyle.css'
import { tokens } from 'style/theme.css'
import { Tooltip } from 'components/Tooltip/Tooltip'
import numeral from 'numeral'

dayjs.extend(customParseFormat)

const getImportanceClassNames = (importance: number, index: number) => {
	return clsx(tableStyle.importanceCircle, { [tableStyle.importanceCircleActive]: index < importance })
}

const getColorBasedOnComparison = (value: number, estimate: number): string => {
	if (Number.isNaN(value)) {
		return 'inherit'
	} else if (value > estimate) {
		return tokens.colors['primary.500']
	} else if (value < estimate) {
		return tokens.colors['destructive.600']
	} else {
		return 'inherit'
	}
}

const getUnitFormat = (value: string | number, unit?: string) => {
	if (Number.isNaN(value)) {
		return '-'
	}
	if (unit == '%') {
		return `${value}${unit}`
	}
	if (unit == '$') {
		return `${unit}${numeral(value).format('0.00a').toUpperCase()}`
	}
	return `${numeral(value).format('0.00a').toUpperCase()} ${unit}`
}

const economicColumnHelper = createColumnHelper<EconomicDataInterface>()

export const economicColumnsData = [
	economicColumnHelper.accessor(row => row.date, {
		id: 'date',
		header: 'Date',
		cell: info => dayjs(info.getValue()).format('M/D')
	}),
	economicColumnHelper.accessor(row => row.time, {
		id: 'time',
		header: 'Time',
		cell: info => {
			const timeString = info.getValue()
			return dayjs(timeString, 'HH:mm:ss').format('h:mm A')
		}
	}),
	economicColumnHelper.accessor(row => row.country, {
		id: 'country',
		header: 'Country',
		cell: info => info.getValue()
	}),
	economicColumnHelper.accessor(row => row.importance, {
		id: 'importance',
		header: 'Importance',
		cell: info => {
			const importance = info.getValue()
			return (
				<Box className={tableStyle.importanceWrapper}>
					{[...Array(5)].map((_, index) => (
						<Box key={index} className={getImportanceClassNames(importance, index)}></Box>
					))}
				</Box>
			)
		}
	}),
	economicColumnHelper.accessor(row => row.event, {
		id: 'event',
		header: 'Event',
		cell: info => {
			const description = info.row.original.description
			return (
				<Tooltip content={description}>
					<div style={{ display: 'inline-block' }}>{info.getValue()}</div>
				</Tooltip>
			)
		}
	}),
	economicColumnHelper.accessor(row => row.forecast, {
		id: 'forecast',
		header: 'Forecast',
		cell: info => {
			const unit = info.row.original.unit
			return getUnitFormat(info.getValue(), unit)
		}
	}),
	economicColumnHelper.accessor(row => row.actual, {
		id: 'actual',
		header: 'Actual',
		cell: info => {
			const actual = parseFloat(info.getValue())
			const unit = info.row.original.unit
			const forecast = parseFloat(info.row.original.forecast)
			const color = getColorBasedOnComparison(actual, forecast)

			return <Box style={{ color }}>{getUnitFormat(actual, unit)}</Box>
		}
	}),
	economicColumnHelper.accessor(row => row.previous, {
		id: 'previous',
		header: 'Previous',
		cell: info => {
			const unit = info.row.original.unit
			return getUnitFormat(info.getValue(), unit)
		}
	})
]

const earningsColumnHelper = createColumnHelper<EarningsDataInterface>()

export const earningsColumnsData = [
	earningsColumnHelper.accessor(row => row.date, {
		id: 'date',
		header: 'Date',
		cell: info => dayjs(info.getValue()).format('M/D')
	}),
	earningsColumnHelper.accessor(row => row.time, {
		id: 'time',
		header: 'Time',
		cell: info => {
			const timeString = info.getValue()
			return dayjs(timeString, 'HH:mm:ss').format('h:mm A')
		}
	}),
	earningsColumnHelper.accessor(row => row.mcap, {
		id: 'mcap',
		header: 'Mcap',
		cell: info => {
			return getUnitFormat(info.getValue(), '$')
		}
	}),
	earningsColumnHelper.accessor(row => row.ticker, {
		id: 'ticker',
		header: 'Ticker',
		cell: info => {
			return <span style={{ color: tokens.colors['primary.500'] }}>{info.getValue()}</span>
		}
	}),

	earningsColumnHelper.accessor(row => row.importance, {
		id: 'importance',
		header: 'Importance',
		cell: info => {
			const importance = info.getValue()
			return (
				<Box className={tableStyle.importanceWrapper}>
					{[...Array(5)].map((_, index) => (
						<Box key={index} className={getImportanceClassNames(importance, index)}></Box>
					))}
				</Box>
			)
		}
	}),

	earningsColumnHelper.accessor(row => row.name, {
		id: 'name',
		header: 'Name',
		cell: info => info.getValue()
	}),
	earningsColumnHelper.accessor(row => row.revenue_est, {
		id: 'revenue_est',
		header: 'rev est.',
		sortUndefined: 'last',
		cell: info => {
			const parsedValue = parseFloat(info.getValue())
			return getUnitFormat(parsedValue, '')
		}
	}),
	earningsColumnHelper.accessor(row => row.revenue, {
		id: 'revenue',
		header: 'revenue',
		cell: info => {
			const revenueEst = parseFloat(info.row.original.revenue_est)
			const rev = parseFloat(info.getValue())
			const color = getColorBasedOnComparison(rev, revenueEst)

			return <Box style={{ color }}>{getUnitFormat(rev, '')}</Box>
		}
	}),
	earningsColumnHelper.accessor(row => row.eps_est, {
		id: 'eps_est',
		header: 'EPS est.',
		cell: info => info.getValue()
	}),
	earningsColumnHelper.accessor(row => row.eps, {
		id: 'eps',
		header: 'EPS',
		cell: info => {
			const epsEst = parseFloat(info.row.original.eps_est)
			const eps = parseFloat(info.getValue())

			const color = getColorBasedOnComparison(eps, epsEst)

			return <Box style={{ color }}>{getUnitFormat(eps, '')}</Box>
		}
	})
]
