import React, { useState, useRef, useEffect } from 'react'
import numeral from 'numeral'

import { Box } from 'components/layout/box'
import * as styles from './BotCard.css'

import { RiskChip } from 'components/MavbotsArena/components/RiskChip/RiskChip'
import { ProfileAvatar } from 'components/MavbotsArena/components/Avatar/Avatar'

import { Text } from 'components/typography/text'
import { Button } from 'components/inputs/button'
import { GraphIcon } from 'assets/icons/icons'
import { InlineIcon } from 'components/icons/inline-icon'
import { TradeNews } from './TradeNews'
import { TradesList } from 'components/MavbotsArena/components/TradesList/TradesList'
import { tokens } from 'style/theme.css'
import { useTabsContext } from 'components/Tabs/TabsProvider'
import { useArenaContext } from 'context/ArenaContext'
import { Trader } from 'api/mavbots-arena/fetchBotStats'
import dayjs from 'dayjs'
import { getRandomSpecialCharacters } from 'helpers/getRandomSpecialCharacters'
import { useMainAppContext } from 'context/MainAppContext'
import { followBot, unfollowBot } from 'api/mavbots-arena'
import { useOktaAuth } from '@okta/okta-react'

interface BotCardProps {
	data: Trader
}

export const BotCard = ({ data }: BotCardProps) => {
	const { onActiveTabChange } = useTabsContext()
	const { setArenaSelectedBot, arenaBotsTrades } = useArenaContext()
	const { feedNotificationList, setFeedNotificationList } = useMainAppContext()
	const [isFlipped, setIsFlipped] = useState(false)
	const { oktaAuth } = useOktaAuth()
	const token = oktaAuth.getAccessToken()
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState<string | null>(null)

	// find arenaBotsTrades for this bot
	const trades = arenaBotsTrades.find(trades => trades.bot_id === data.bot_id)

	// Refs for cardFront and cardBack
	const cardFrontRef = useRef<HTMLDivElement | null>(null)
	const cardBackRef = useRef<HTMLDivElement | null>(null)
	const [cardHeight, setCardHeight] = useState(0)

	// Check if user is already following this bot
	const isFollowing = feedNotificationList?.includes(`arena_${data.bot_name.toLowerCase()}`)

	const handleToggleFollow = async () => {
		setIsLoading(true)
		setError(null)
		if (!token) return

		try {
			if (isFollowing) {
				// If the user is already following, call unfollow
				const updatedNotificationList = await unfollowBot(token, `arena_${data.bot_name.toLowerCase()}`)
				setFeedNotificationList(updatedNotificationList)
			} else {
				// Else follow
				const updatedNotificationList = await followBot(token, `arena_${data.bot_name.toLowerCase()}`)
				setFeedNotificationList(updatedNotificationList)
			}
		} catch (err: any) {
			console.error('Error toggling follow:', err)
			setError(err.message || 'An unexpected error occurred.')
		} finally {
			setIsLoading(false)
		}
	}

	// Set the height of cardBack based on cardFront
	useEffect(() => {
		if (cardFrontRef.current) {
			setCardHeight(cardFrontRef.current.offsetHeight)
		}
	}, [isFlipped])

	const handleFlipClick = () => {
		setIsFlipped(prev => !prev)
	}

	const handleAvatarClick = () => {
		onActiveTabChange('Roster')
		setArenaSelectedBot(data)
	}

	return (
		<Box className={styles.cardWrapper}>
			<Box className={`${styles.card} ${isFlipped ? styles.flippedCard : ''}`}>
				{/* Front of the card */}
				<div ref={cardFrontRef} className={`${styles.cardSide} ${styles.cardFront}`}>
					<Box display="flex" flexDirection="column" gap={4} flexGrow={1} justifyContent="space-between">
						<Box className={styles.mainCardGrid}>
							{/* Avatar */}
							<Box className={styles.avatarWrapper}>
								<ProfileAvatar
									style={{ width: '88px', height: '88px' }}
									videoAvatar={data.videoAvatar}
									fallbackImg={data.avatar}
									role={data.role}
									shouldHover
									handleClick={handleAvatarClick}
								/>
								<RiskChip riskLevel={data.riskLevel} />
							</Box>
							{/* TOP PART */}
							<Box display="flex" flexDirection="column" gap={4}>
								<Box className={styles.headerWrapper}>
									<Box>
										<Text variant="body1Bold">{data.bot_name}</Text>
									</Box>

									<Button
										variant="primary"
										size="small"
										style={{ padding: '8px' }}
										onClick={handleToggleFollow}
										disabled={isLoading}>
										{isFollowing ? 'Unfollow' : 'Follow'}
									</Button>
								</Box>

								{/* BOTTOM PART */}
								<Box className={styles.infoGrid}>
									{/* Details Mid */}
									<Box>
										<Box>
											<Text variant="body2Bold" color="neutral.100">
												<Text as="span" variant="body2" color="neutral.500">
													Strategy:{' '}
												</Text>
												{data.strategy}
											</Text>
										</Box>

										<TradesList trades={data.trades} openPositions={data.open_positions}>
											<Text as="span" variant="body2" color="neutral.500">
												{data.role === 'portfolio' ? 'Portfolio: ' : 'Trades: '}
											</Text>
										</TradesList>
										<Text variant="body2Bold" color="neutral.100" style={{ display: 'flex', gap: '4px' }}>
											<Text
												as="span"
												variant="body2"
												color="neutral.500"
												style={{ display: 'flex', alignItems: 'center' }}>
												<InlineIcon icon={GraphIcon} style={{ marginRight: '4px' }} />
												YTD Perf:
											</Text>
											<span
												style={{
													color:
														// @ts-ignore
														numeral(data.perf_ytd).value() > 0
															? tokens.colors['primary.500']
															: tokens.colors['warning.700']
												}}>
												{data.perf_ytd}
											</span>
										</Text>
									</Box>

									{/* Details Grid */}
									<Box className={styles.detailsGrid}>
										<Text variant="body2Bold" color="neutral.100">
											<Text as="span" variant="body2" color="neutral.500">
												5Y Perf:{' '}
											</Text>
											{data.perf_5y || <span className={styles.blurValue}>{getRandomSpecialCharacters(4)}</span>}
										</Text>

										<Text variant="body2Bold" color="neutral.100" textAlign={'right'}>
											<Text as="span" variant="body2" color="neutral.500">
												Avg Win:{' '}
											</Text>
											{data.win_avg || <span className={styles.blurValue}>{getRandomSpecialCharacters(4)}</span>}
										</Text>

										<Text variant="body2Bold" color="neutral.100">
											<Text as="span" variant="body2" color="neutral.500">
												Win Rate:{' '}
											</Text>
											{data.win_rate || <span className={styles.blurValue}>{getRandomSpecialCharacters(4)}</span>}
										</Text>

										<Text variant="body2Bold" color="neutral.100" textAlign={'right'}>
											<Text as="span" variant="body2" color="neutral.500">
												Avg Loss:{' '}
											</Text>
											{data.loss_avg || <span className={styles.blurValue}>{getRandomSpecialCharacters(4)}</span>}
										</Text>
									</Box>
								</Box>
							</Box>
						</Box>
						{trades && <TradeNews tradeHistory={trades.trade_history} />}
					</Box>
					<Box className={styles.showMoreButton} onClick={handleFlipClick}>
						Show More
					</Box>
				</div>

				{/* Back of the card */}
				<div
					ref={cardBackRef}
					className={`${styles.cardSide} ${styles.cardBack}`}
					style={{ height: `${cardHeight}px` }}>
					<Box className={styles.tradeHistoryContainer}>
						<Text variant="body1Bold" textAlign="center" color="primary.500">
							Recent trade history
						</Text>
						<Box as="ul" paddingLeft={4} className={styles.tradeHistoryList}>
							{trades &&
								trades.trade_history.map(trade => (
									<Box as="li" key={trade.id} className={styles.listItem}>
										<Text className={styles.dateTime} variant="body2">
											{dayjs(trade.datetime).format('MM/DD/YY h:mmA')}
										</Text>

										<Box className={`${styles.message}`}>
											<Text
												className={trade.message.includes('Bought') ? styles.greenMessage : styles.redMessage}
												variant="body2">
												{trade.message.split(' ')[0]}
											</Text>

											<Text className={styles.boldSymbol} variant="body2">
												{trade.message.split(' ')[1]}
											</Text>

											<Text variant="body2">{trade.message.split(' ').slice(2).join(' ')}</Text>
										</Box>

										<Text className={styles.size} variant="body2">
											{trade.size}
										</Text>
									</Box>
								))}
						</Box>
					</Box>

					<Box className={styles.flipButton} onClick={handleFlipClick}>
						Flip back
					</Box>
				</div>
			</Box>
		</Box>
	)
}
