import "src/style/theme.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/style/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA3WWWW7bMBCG33sKAUaABKhTcri7T5Il3iOLszmxXWdzUvTunaEDlEOyEmzDH0aj2X6Si/12+9L9/tZ18/lzOKzXO7HoxM/sv8T/52D2q6ecAtESqkUnC6QRVXYGoRAnObKIdoecuPQkZ37RASdh0c0gXTm9QBoF3Tm9RDr0Q1yGnF4V+V4vOmd9TlYYCXhmdIPIaoZuMTZjGbqjMHS0ccjpPdJxGKdJ5fShGfK6GfIj0l7RndMnpMGH2E853SA1I9053SLVWveGJbqjYgaIitn+IipBA8tiv+j2t5cXp0p975T93mn8xTkRZ7nRc9NIG2b00jSy3NNr08hzo7emUeBG71TodOX0gHSKEbuS0w9K3fcyDoqV+pPaokPPfUiUzswPFgqMCppZNxpvGUYJzYTQ3hmGFb3TBa051tSxHqbAuiBRSjM1gvbc2lKSgNO0ZNjR4ECQRYAoq5kLVkYmIhnSK5UdOP4S1xg1w6SucRmXk2P4iko1RDfyReWaSmWn2PMAV1+l6tkEyps2RsnNpO2V7hkm2UnjBR9jeZ+srVE8bhKe1EYBt14fx8RPvLCPRyyXPO6nhCdfBLhJeOwdD3Cb8HLQvFQkv2h6kHxOSH9jcE5wvE9tMJPhAT7TKgBaCDbI8oVa7JURvPOvx3Rs5E7ejnHHkafznkTSx57P4CEtc8F47vsjOQk68Hp/NjGQdjAXq9krQTazBNIONljwNQlIO3qUo2T1Bt2UPJg2ts0tBVA7c9y1GPPF3gEh7WwMoVw2282KMdSK2B3SR1r8muOuRosBXZLX5eqfKejCFNiuANdp+TsVHd0/0PPJWXpO4ccefTD7VRn9TXurv23t9XBH1FX4vj4DwANt5bXfdetsAI9Ea7+oMCgZygtqB6guVTKUli4Z6qp6FkVlS4aK8iV7oT6X8JXOCSV8ozLJKqB3wqpO80Dc1rX6+A//bHZB0SHu3Ndc1t1R0OyOUq3uqHSeq+NQpm6PstSe2tS1uqZQSporR6GYbIFQTK5AqCVfINRMKBBqIxiOVvUpVNG5ThUMx18aYn/+Aus/j0EtCwAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/components/RightSidePanel/PanelTop/PanelTop.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/RightSidePanel/PanelTop/PanelTop.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA9VWbY/bKBD+nl+BuooUS2Fl/BInzpf2bvs7KmywwwUbHyYv22r/ewHbWUiy2zu1UlVZcRhmYGaeZ8Dz+AXVVXaqTiH4NgOgEz1TTLQ5kJRjxY50q2e/QtYSes4B2s5eZo/TEnS1BBe94Adll5wYUTu9IAznRmxYC1+nwu5sJneU1TuVgyQ67oysRJeD0IzkoLDjDhPC2joHRywXEPab837fRfvA6AohCZVQYsIOvba3TxwN2xe43NdSHFrir/038HKCyPqWuO0rIZsc9CXmdBEG3jQUOiamk9RBDuEZNdMJ4yH5YUh9gPKC6sXU4lSKVtFWZ/Xhw/ZN2F4h4LRSd9AohFKiuQhe+qzdUcmUoykFFzIfsuiw1O4dZa+eOdXpCs6IMz3SZJBE3dn8rsHUmbAS1sat3nExe4hS84BwvtTj+CnKPmuWHSFyhdgKf2VPfz99AokVUIwiFIHUNVsNmiRbJRlYWyGMzGNrahZM4RbCxgdPtNgzBRvc73PAWUuxfA3xoaoqk1EwVZNZtnzbzJas3kkD2FiiNE70XPIDoTe14/AdWaJvSgkFnlX8ajVVAOcgfIx7QHFP9d6eefKGeTSai4Py7NN3TqUtr+l4uJU0ltioGksgRf45TZNBJqzvOH7OQcWpnfjn0CtWPcNLiZf6TaVRYc7qFmoAm96dvpf91h4SW7DucW0sG+VB9kbTCTbtMQ4hPepte7uPD0S+E0cqLRxu+V6zjuKU0HoJZF3gBQrjJYiQfqE4WZqK0aUHHlbZU7pe2dIL3ogTXdE8us9xpcYg/tMNcO+wQ7TxiIlSn5hJfTkSZhk0N8jlbkiHe9jF4W6+j5shP8462GHjrBP8uRbtAkZzEC4tBJf/zUaDs0nnVlqCLJuDVWoG8RykmR6kaA7ieO4js/qJCk1TD4kku1+i7yAR/kElvPq9JZz9BFEo9JmamPkfTCXRH3XfZL+XrPWPyEJj23UhyCPgvYuCErelW11dPqN8J8Rd8Ivoq7Ql7NlX/RleD97szGkMIQtDD4qNhUJ0uGRK+9VkDz3oGRactgQ2gtB8CBgSQWo6ZUyk6GDFuHabg4If5EJ/Av0kWtH6TR62zm70HxtKGNYdgKS0BbglYOE2wdFawx/YpVdhO3sVXJT7rZ27z6vbMHot400DftPwvrie8a/07H7Bru6Gl9nLd2c2TntxDAAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var contentBox = '_1gf7wfw1';
export var contentBoxClosing = '_1gf7wfw4';
export var contentBoxExpanded = '_1gf7wfw2';
export var contentBoxOpening = '_1gf7wfw3';
export var leftIconWrapper = '_1gf7wfw7';
export var middleIconWrapper = '_1gf7wfw6';
export var notificationNumber = '_1gf7wfw8';
export var panelTop = '_1gf7wfw0';
export var rightIconWrapper = '_1gf7wfw5';
export var topBackgroundReflection = '_1gf7wfwa';
export var topReflection = '_1gf7wfw9';