import axios from 'axios'

const axiosClient = axios.create()

axiosClient.defaults.headers.common = {
	'Content-Type': 'application/json',
	Accept: 'application/json',
	'Access-Control-Allow-Origin': '*',
	'Access-Control-Allow-Credentials': true,
	'Access-Control-Allow-Headers': 'X-Requested-With,content-type'
}

export type LeaderboardDataInterface = {
	bot_id: number
	bot_name: string
	bot_avatar: string
	strategy: string
	ytd_performance: string
	ytd_performance_week_ago: string
	chart_performance: Record<string, number>
}

export const fetchLeaderboard = async (accessToken: string | undefined): Promise<LeaderboardDataInterface[]> => {
	const response = await axiosClient.get(`${process.env.REACT_APP_API_SERVER}/storage/arena/leaderboard`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})

	return response.data.result
}
