import julian from 'assets/avatars/julian.jpg'
import sophie from 'assets/avatars/sophie.jpg'
import alex from 'assets/avatars/alex.jpg'

import arjun from 'assets/arena/arjun.jpg'
import geoffrey from 'assets/arena/geoffrey.jpg'
import marco from 'assets/arena/marco.jpg'
import hannah from 'assets/arena/hannah.jpg'
import ethan from 'assets/arena/ethan.jpg'
import phillip from 'assets/arena/phillip.jpg'
import isaac from 'assets/arena/isaac.jpg'
import paula from 'assets/arena/paula.jpg'
import ella from 'assets/arena/ella.jpg'
import rohan from 'assets/arena/rohan.jpg'
import sebastian from 'assets/arena/sebastian.jpg'
import lin from 'assets/arena/lin.jpg'
import sana from 'assets/arena/sana.jpg'
import lucas from 'assets/arena/lucas.jpg'
import rohit from 'assets/arena/rohit.jpg'
import dylan from 'assets/arena/dylan.jpg'

interface Notification {
	actor: string
	avatar: string
	link: string
}

export const notification: Notification[] = [
	{
		actor: 'news_sophie',
		avatar: sophie,
		link: '/news'
	},
	{
		actor: 'herd_alex',
		avatar: alex,
		link: '/herd/herd-pulse'
	},
	{
		actor: 'som_julian',
		avatar: julian,
		link: '/state-of-market'
	},
	// Arena bots
	{
		actor: 'arena_arjun',
		avatar: arjun,
		link: '/mavbots-arena'
	},
	{
		actor: 'arena_geoffrey',
		avatar: geoffrey,
		link: '/mavbots-arena'
	},
	{
		actor: 'arena_marco',
		avatar: marco,
		link: '/mavbots-arena'
	},
	{
		actor: 'arena_hannah',
		avatar: hannah,
		link: '/mavbots-arena'
	},
	{
		actor: 'arena_ethan',
		avatar: ethan,
		link: '/mavbots-arena'
	},
	{
		actor: 'arena_phillip',
		avatar: phillip,
		link: '/mavbots-arena'
	},
	{
		actor: 'arena_isaac',
		avatar: isaac,
		link: '/mavbots-arena'
	},
	{
		actor: 'arena_paula',
		avatar: paula,
		link: '/mavbots-arena'
	},
	{
		actor: 'arena_ella',
		avatar: ella,
		link: '/mavbots-arena'
	},
	{
		actor: 'arena_rohan',
		avatar: rohan,
		link: '/mavbots-arena'
	},
	{
		actor: 'arena_sebastian',
		avatar: sebastian,
		link: '/mavbots-arena'
	},
	{
		actor: 'arena_lin',
		avatar: lin,
		link: '/mavbots-arena'
	},
	{
		actor: 'arena_sana',
		avatar: sana,
		link: '/mavbots-arena'
	},
	{
		actor: 'arena_lucas',
		avatar: lucas,
		link: '/mavbots-arena'
	},
	{
		actor: 'arena_rohit',
		avatar: rohit,
		link: '/mavbots-arena'
	},
	{
		actor: 'arena_dylan',
		avatar: dylan,
		link: '/mavbots-arena'
	},
]
