import { LeaderboardDataInterface } from 'api/mavbots-arena'

export function transformLeaderboardData(rawData: LeaderboardDataInterface[]) {
	if (!rawData) return []

	// 1. Sort by last week's performance (descending) to get old rank
	const byOldPerformance = [...rawData].sort((a, b) => {
		// Convert string -> number
		const perfA = parseFloat(a.ytd_performance_week_ago) || 0
		const perfB = parseFloat(b.ytd_performance_week_ago) || 0
		return perfB - perfA // desc
	})

	// Build a map from bot_id -> oldRank
	const oldRankMap: Record<number, number> = {}
	byOldPerformance.forEach((item, index) => {
		oldRankMap[item.bot_id] = index + 1 // 1-based rank
	})

	// 2. Sort by this week's performance (descending) to get new rank
	const byNewPerformance = [...rawData].sort((a, b) => {
		const perfA = parseFloat(a.ytd_performance) || 0
		const perfB = parseFloat(b.ytd_performance) || 0
		return perfB - perfA
	})

	// Build a map from bot_id -> newRank
	const newRankMap: Record<number, number> = {}
	byNewPerformance.forEach((item, index) => {
		newRankMap[item.bot_id] = index + 1
	})

	// 3. Merge back into final array with rank & rank_change
	return rawData.map(item => {
		const oldRank = oldRankMap[item.bot_id]
		const newRank = newRankMap[item.bot_id]
		return {
			...item,
			rank: newRank, // The rank for "this week's performance"
			rank_change: oldRank - newRank // Positive if they moved up, negative if down
		}
	})
}
