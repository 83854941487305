import { useState, useMemo, useEffect, useRef } from 'react'
import { createChart, ISeriesApi, ColorType, CrosshairMode, AreaData } from 'lightweight-charts'

import * as styles from './LeaderboardChart.css'
import { prepareLeaderboardChartData } from 'helpers/prepareChartData'

interface ChartPerformanceData {
	[key: string]: number
}

interface Props {
	data: ChartPerformanceData
}

export const LeaderboardChart = ({ data }: Props) => {
	const chartContainerRef = useRef<HTMLDivElement>(null)

	const transformedData = Object.entries(data).map(([time, value]) => ({
		time,
		value
	}))

	useEffect(() => {
		if (!chartContainerRef.current) return

		const chart = createChart(chartContainerRef.current, {
			handleScroll: false,
			handleScale: false,
			width: 80,
			// autoSize: true,
			layout: {
				background: { type: ColorType.Solid, color: 'transparent' }
			},
			grid: {
				vertLines: {
					visible: false // Hide vertical grid lines
				},
				horzLines: {
					visible: false // Hide horizontal grid lines
				}
			},
			rightPriceScale: {
				visible: false
			},
			leftPriceScale: {
				visible: false
			},
			timeScale: {
				visible: false
			},
			crosshair: { mode: CrosshairMode.Hidden }
		})

		const areaSeries: ISeriesApi<'Area'> = chart.addAreaSeries({
			lineColor: '#F05E55',
			bottomColor: 'rgba(48, 224, 161, 0)',
			topColor: 'rgba(48, 224, 161, 0.6)',
			lineWidth: 1,
			lastValueVisible: false,
			priceLineVisible: false
		})

		const preparedData = prepareLeaderboardChartData(transformedData)

		areaSeries.setData(preparedData)

		chart.timeScale().fitContent()

		const handleResize = () => {
			chart.applyOptions({ width: chartContainerRef.current?.clientWidth || 0 })
			chart.applyOptions({ height: chartContainerRef.current?.clientHeight || 0 })
		}

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
			chart.remove()
		}
	}, [transformedData])

	return (
		<div className={styles.chartWrapper}>
			<div className={styles.chartContainer} ref={chartContainerRef}></div>
		</div>
	)
}
