import React, { useEffect, useRef } from 'react'
import { createChart, ISeriesApi, ColorType, LineData, AreaData, MouseEventParams } from 'lightweight-charts'
import dayjs from 'dayjs'

import { CumulativeReturns } from 'api/mavbots-arena/fetchBotStats'
import { prepareChartData } from 'helpers/prepareChartData'

import * as styles from './BotProfileChart.css'

interface BotProfileChartProps {
	data: CumulativeReturns[]
}

export const BotProfileChart = ({ data }: BotProfileChartProps) => {
	const chartContainerRef = useRef<HTMLDivElement>(null)
	const leftLabelRef = useRef<HTMLDivElement | null>(null)
	const bottomLabelRef = useRef<HTMLDivElement | null>(null)

	const areaSeriesRef = useRef<ISeriesApi<'Area'>>()
	const lineSeriesRef = useRef<ISeriesApi<'Line'>>()
	const horizontalLineSeriesRef = useRef<ISeriesApi<'Line'>>()
	const animationRef = useRef<number>()
	const prevDataRef = useRef<{ areaChartData: AreaData[]; lineChartData: LineData[] }>({
		areaChartData: [],
		lineChartData: []
	})
	const chartRef = useRef<ReturnType<typeof createChart>>()

	// Chart initialization
	useEffect(() => {
		if (!chartContainerRef.current) return

		// Left Label
		leftLabelRef.current = document.createElement('div')
		leftLabelRef.current.className = styles.leftChartLabel
		leftLabelRef.current.innerHTML = 'Cumulative Returns %'

		// Bottom label
		bottomLabelRef.current = document.createElement('div')
		bottomLabelRef.current.className = styles.bottomChartLabel
		bottomLabelRef.current.innerHTML = 'Index' // or any label text you prefer

		chartContainerRef.current.appendChild(leftLabelRef.current)
		chartContainerRef.current.appendChild(bottomLabelRef.current)

		// Create the chart only once
		chartRef.current = createChart(chartContainerRef.current, {
			handleScroll: true,
			handleScale: true,
			// autoSize: true,

			layout: {
				background: { type: ColorType.Solid, color: 'transparent' },
				textColor: '#989FAE'
			},
			grid: {
				vertLines: { color: 'rgba(68, 74, 88, 0.2)' },
				horzLines: { color: 'rgba(68, 74, 88, 0.2)' }
			},
			leftPriceScale: {
				visible: true,
				ticksVisible: false,
				borderColor: 'rgba(68, 74, 88, 0.2)'
			},
			rightPriceScale: { visible: false },
			crosshair: {
				mode: 1,
				vertLine: {
					color: '#444A58',
					labelBackgroundColor: '#2b2521'
				},
				horzLine: {
					labelVisible: false,
					color: '#444A58',
					labelBackgroundColor: '#21242B'
				}
			}
		})

		// Area series (drawn first to appear below the line series)
		areaSeriesRef.current = chartRef.current.addAreaSeries({
			priceScaleId: 'left',
			lineColor: '#7961F2',
			bottomColor: 'rgba(92,72,196, 0.3)',
			topColor: 'rgba(121, 97, 242, 0.6)',
			lineWidth: 2,
			lastValueVisible: false,
			priceLineVisible: false,
			priceFormat: {
				type: 'custom',
				formatter: (price: number) => (price % 1 === 0 ? price.toFixed(0) : price.toFixed(1))
			}
		})

		// Line series (drawn second to appear above the area series)
		lineSeriesRef.current = chartRef.current.addLineSeries({
			priceScaleId: 'left', // Use the same price scale as the area series
			color: '#989FAE',
			lineWidth: 2,
			lastValueVisible: false,
			priceLineVisible: false,
			priceFormat: {
				type: 'custom',
				formatter: (price: number) => (price % 1 === 0 ? price.toFixed(0) : price.toFixed(1))
			}
		})

		horizontalLineSeriesRef.current = chartRef.current.addLineSeries({
			priceScaleId: 'left',
			color: '#000000',
			lineWidth: 1,
			lastValueVisible: false,
			priceLineVisible: false
		})

		chartRef.current.timeScale().fitContent()

		// ---------------------------
		// THE CROSSHAIR TOOLTIP
		// ---------------------------
		const tooltip = document.createElement('div')
		tooltip.className = styles.crosshairTooltip // define this class in your CSS
		tooltip.style.display = 'none'
		chartContainerRef.current.appendChild(tooltip)

		const handleCrosshairMove = (param: MouseEventParams) => {
			// If no valid crosshair point, hide tooltip
			if (
				!param.time ||
				!param.point ||
				!chartContainerRef.current ||
				param.point.x < 0 ||
				param.point.x > chartContainerRef.current.clientWidth ||
				param.point.y < 0 ||
				param.point.y > chartContainerRef.current.clientHeight
			) {
				tooltip.style.display = 'none'
				return
			}

			const areaData = param.seriesData.get(areaSeriesRef.current!)
			const lineData = param.seriesData.get(lineSeriesRef.current!)

			// If there's no data at that point, hide tooltip
			if (!areaData && !lineData) {
				tooltip.style.display = 'none'
				return
			}

			tooltip.style.display = 'block'
			tooltip.style.left = param.point.x + 'px'
			tooltip.style.top = param.point.y + 'px'

			const timeString = dayjs(param.time as string).format('YYYY-MM-DD')
			const areaValue = areaData ? (areaData as AreaData).value?.toFixed(2) : '—'
			const lineValue = lineData ? (lineData as LineData).value?.toFixed(2) : '—'

			tooltip.innerHTML = `
				<div class="${styles.tooltipRow}">
					Date: <b>${timeString}</b>
				</div>
				<div class="${styles.tooltipRow}">
					Bot: <b>${areaValue}%</b>
				</div>
				<div class="${styles.tooltipRow}">
					Benchmark: <b>${lineValue}%</b>
				</div>
			`
		}

		chartRef.current.subscribeCrosshairMove(handleCrosshairMove)

		// Resize handling using ResizeObserver
		const resizeObserver = new ResizeObserver(() => {
			if (chartContainerRef.current && chartRef.current) {
				const { clientWidth, clientHeight } = chartContainerRef.current
				chartRef.current.applyOptions({
					width: clientWidth,
					height: clientHeight,
					timeScale: {
						fixLeftEdge: true,
						fixRightEdge: true,
						minBarSpacing: 0.1
					}
				})

				chartRef.current.timeScale().fitContent()
			}
		})

		if (chartContainerRef.current) {
			resizeObserver.observe(chartContainerRef.current)
		}

		return () => {
			resizeObserver.disconnect()

			// Early return if refs are null
			if (!chartContainerRef.current || !leftLabelRef.current || !bottomLabelRef.current) return

			// Remove left label if it exists
			if (chartContainerRef.current.contains(leftLabelRef.current)) {
				chartContainerRef.current.removeChild(leftLabelRef.current)
				leftLabelRef.current = null // Reset leftLabelRef
			}

			// Remove bottom label if it exists
			if (chartContainerRef.current.contains(bottomLabelRef.current)) {
				chartContainerRef.current.removeChild(bottomLabelRef.current)
				bottomLabelRef.current = null // Reset bottomLabelRef
			}

			chartRef.current?.remove()
		}
	}, [])

	// Update chart data with animation when `data` changes
	useEffect(() => {
		if (!areaSeriesRef.current || !lineSeriesRef.current || !chartRef.current) return

		const { areaChartData, lineChartData } = prepareChartData(data)

		if (prevDataRef.current.areaChartData.length > 0) {
			// Animate both the area and line chart data simultaneously
			animateDataTransitionSimultaneous(
				prevDataRef.current.lineChartData,
				lineChartData,
				lineSeriesRef.current,
				prevDataRef.current.areaChartData,
				areaChartData,
				areaSeriesRef.current
			)
		} else {
			// Set data for both series for the first time without animation
			areaSeriesRef.current.setData(areaChartData)
			lineSeriesRef.current.setData(lineChartData)
		}

		// Store current data as previous for the next update
		prevDataRef.current = { areaChartData, lineChartData }

		// Fit content after the new data is set
		chartRef.current.timeScale().fitContent()
	}, [data])

	// Function to animate the transition of both series simultaneously
	const animateDataTransitionSimultaneous = (
		oldLineData: LineData[],
		newLineData: LineData[],
		lineSeries: ISeriesApi<'Line'>,
		oldAreaData: AreaData[],
		newAreaData: AreaData[],
		areaSeries: ISeriesApi<'Area'>
	) => {
		const duration = 500 // Animation duration in ms
		const startTime = performance.now()

		const oldLineDataMap = new Map(oldLineData.map(point => [point.time, point]))
		const oldAreaDataMap = new Map(oldAreaData.map(point => [point.time, point]))

		const animate = (currentTime: number) => {
			const elapsed = currentTime - startTime
			const progress = Math.min(elapsed / duration, 1)

			// Interpolate line series data
			const interpolatedLineData = newLineData.map(newPoint => {
				const oldPoint = oldLineDataMap.get(newPoint.time) || { value: newPoint.value }
				const interpolatedValue = oldPoint.value + (newPoint.value - oldPoint.value) * progress
				return { time: newPoint.time, value: interpolatedValue }
			})

			// Interpolate area series data
			const interpolatedAreaData = newAreaData.map(newPoint => {
				const oldPoint = oldAreaDataMap.get(newPoint.time) || { value: newPoint.value }
				const interpolatedValue = oldPoint.value + (newPoint.value - oldPoint.value) * progress
				return { time: newPoint.time, value: interpolatedValue }
			})

			// Set data for both series at the same time
			lineSeries.setData(interpolatedLineData)
			areaSeries.setData(interpolatedAreaData)

			// Continue animation if progress is not yet complete
			if (progress < 1) {
				animationRef.current = requestAnimationFrame(animate)
			}
		}

		if (animationRef.current) {
			cancelAnimationFrame(animationRef.current)
		}
		animationRef.current = requestAnimationFrame(animate)
	}

	useEffect(() => {
		if (!horizontalLineSeriesRef.current || data.length === 0) return

		// Calculate min and max time dynamically
		const times = data.map(item => item.time)
		const minTime = times[0]
		const maxTime = times[times.length - 1]

		// Set horizontal line data
		horizontalLineSeriesRef.current.setData([
			{ time: minTime, value: 0 },
			{ time: maxTime, value: 0 }
		])
	}, [data])

	return (
		<div className={styles.chartWrapper}>
			<div className={styles.chartContainer} ref={chartContainerRef} />
		</div>
	)
}
