import { createColumnHelper } from '@tanstack/react-table'
import dayjs from 'dayjs'
import { Box } from 'components/layout/box'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { LeaderboardDataInterface } from 'api/mavbots-arena'
import { tokens } from 'style/theme.css'
import { Text } from 'components/typography/text'
import { UpArrow } from 'assets/icons/icons'

import * as styles from './Leaderboard.css'
import { LeaderboardChart } from './LeaderboardChart'

dayjs.extend(customParseFormat)

const leaderboardColumnHelper = createColumnHelper<
	LeaderboardDataInterface & {
		rank: number
		rank_change: number
	}
>()

export const leaderboardColumnsData = [
	leaderboardColumnHelper.accessor(row => row.rank, {
		id: 'rank',
		header: 'Rank',
		cell: info => {
			const name = info.row.original.bot_name
			const img = info.row.original.bot_avatar
			const rank = info.getValue()

			const imgSrc = `arena/${img.toLowerCase()}`

			return (
				<Box display="flex" gap={1} alignItems="center" style={{ cursor: 'pointer' }}>
					<Text variant="body2" color="neutral.50">
						{rank}
					</Text>
					<Box as="img" className={styles.tableCellAvatar} alt={name} src={imgSrc} />
					<Text variant="body2" color="neutral.50" className={styles.tableCellName}>
						{name}
					</Text>
				</Box>
			)
		}
	}),
	leaderboardColumnHelper.accessor(row => row.rank_change, {
		id: 'rank_change',
		header: () => (
			<Box as="span" className={styles.tHead} textAlign="center" display="block">
				Change/week
			</Box>
		),

		cell: info => {
			// const numericValue = Number(info.getValue())
			// const isPositive = numericValue > 0

			const numericValue = info.getValue()
			const isPositive = numericValue > 0
			const displayChange = numericValue !== 0 ? Math.abs(numericValue) : 0

			return (
				<Box display="flex" alignItems="center" gap={1} justifyContent="center">
					<Text variant="body2" color="neutral.50">
						<span style={{ minWidth: '14px', display: 'inline-block' }}>
							{/* {info.getValue()} */}
							{displayChange}
						</span>
					</Text>
					{numericValue !== 0 ? (
						<UpArrow
							color={isPositive ? tokens.colors['primary.500'] : tokens.colors['warning.700']}
							isActive={isPositive}
						/>
					) : (
						<div className={styles.tableCellCircle} />
					)}
				</Box>
			)
		}
	}),
	leaderboardColumnHelper.accessor(row => row.strategy, {
		id: 'strategy',
		header: () => (
			<Box as="span" className={styles.tHead} textAlign="center" display="block">
				Strategy
			</Box>
		),
		cell: info => {
			return <Box textAlign="center">{info.getValue()}</Box>
		}
	}),
	leaderboardColumnHelper.accessor(row => row.ytd_performance, {
		id: 'ytd_performance',
		header: () => (
			<Box as="span" className={styles.tHead} textAlign="center" display="block">
				YTD Perf
			</Box>
		),

		cell: info => {
			const thisWeekValue = Number(info.getValue()).toFixed(1)

			return <Box textAlign="center">{thisWeekValue}%</Box>
		}
	}),

	leaderboardColumnHelper.accessor(row => row.chart_performance, {
		id: 'chart_performance',
		header: '',
		cell: info => {
			const data = info.getValue()
			return (
				<div>
					<LeaderboardChart data={data} />
				</div>
			)
		}
	})
]
