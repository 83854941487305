import React from 'react'
import numeral from 'numeral'
import NumberFlow from '@number-flow/react'
import { AnimatePresence, motion } from 'motion/react'

import { Box } from 'components/layout/box'
import { Text } from 'components/typography/text'
import * as styles from './WidgetCard.css'
import { getColorForValue } from 'helpers/getColorForValue'
import { CircleChart } from './CircleChart'
import dayjs from 'dayjs'
import { WidgetCard } from 'api/mavbots-arena/fetchBotStats'

const renderSubValue = (value: number | string | undefined, numberStyle: 'percent' | 'number') => {
	if (value === undefined) {
		return <span>??</span>
	}
	const color = getColorForValue(value)
	const numericValue = numeral(value).value()
	if (numericValue === null || isNaN(numericValue)) {
		return <span>-</span> // Handle invalid numbers
	}

	const formattedValue =
		numericValue >= 0
			? `+${
					numberStyle === 'number'
						? Number.isInteger(value)
							? numeral(value).format('0,0')
							: numeral(value).format('0,0.00')
						: `${numeral(value).format('0,0.00')}%`
			  }`
			: numberStyle === 'number'
			? Number.isInteger(value)
				? numeral(value).format('0,0')
				: numeral(value).format('0,0.00')
			: `${numeral(value).format('0,0.00')}%`

	return <span style={{ color }}>{formattedValue}</span>
}

const Label = ({ children }: { children: React.ReactNode }) => (
	<Text variant="metadataBold" color="neutral.100">
		{children}
	</Text>
)

const MainValue = ({ children }: { children: React.ReactNode }) => (
	<Text variant="h2" fontWeight="bold" color="neutral.50">
		{children}
	</Text>
)
const SubValue = ({ children }: { children: React.ReactNode }) => (
	<Text variant="metadata" color="neutral.100">
		{children}
	</Text>
)

const DateLabel = ({ children }: { children: React.ReactNode }) => (
	<Text variant="metadataBold" color="neutral.300" style={{ marginTop: 'auto' }}>
		{children}
	</Text>
)

export const WidgetCardValue = ({ text_1, text_2, date }: WidgetCard) => {
	// Safely handle text_1 for number comparison and formatting
	const text1Value = typeof text_1 === 'number' ? text_1 : parseFloat(text_1 as string)

	return (
		<Box className={styles.widgetCard}>
			<Box className={styles.contentFlex}>
				<Label>Value</Label>
				<Box display="flex" flexDirection="column" gap={0.5}>
					<MainValue>
						<NumberFlow
							style={{ lineHeight: 0.8 }}
							value={text1Value}
							locales="en-US"
							format={{ style: 'currency', currency: 'USD' }}
						/>
					</MainValue>
					<SubValue>{text_2}</SubValue>
				</Box>
				<DateLabel>Since {dayjs(date).format('MMM D YYYY')}</DateLabel>
			</Box>
		</Box>
	)
}

export const WidgetCardTotalReturn = ({ text_1, text_2, text_3, date }: WidgetCard) => {
	// Safely handle text_1 for number comparison
	const text1Value = typeof text_1 === 'number' ? text_1 : parseFloat(text_1 as string)

	return (
		<Box className={styles.widgetCard}>
			<Box className={styles.contentFlex}>
				<Label>Total Return</Label>
				<Box display="flex" flexDirection="column" gap={0.5}>
					<MainValue>
						<NumberFlow
							style={{ lineHeight: 0.8 }}
							value={text1Value / 100} // Convert to a fraction for percentage display
							locales="en-US"
							format={{
								style: 'percent',
								minimumFractionDigits: 2,
								maximumFractionDigits: 2
							}}
							prefix={text1Value >= 0 ? '+' : ''}
						/>
					</MainValue>
					<SubValue>
						({renderSubValue(text_2, 'percent')} {text_3})
					</SubValue>
					{/* <CircleChart value={80} maxValue={100} label="Win Rate" /> */}
				</Box>
				<DateLabel>Since {dayjs(date).format('MMM D YYYY')}</DateLabel>
			</Box>
		</Box>
	)
}

export const WidgetCardRiskAdjustedReturn = ({ text_1, text_2, text_3, date }: WidgetCard) => {
	const text1Value = typeof text_1 === 'number' ? text_1 : parseFloat(text_1 as string)

	return (
		<Box className={styles.widgetCard}>
			<Box className={styles.contentFlex}>
				<Label>Risk Adjusted Return</Label>
				<Box display="flex" flexDirection="column" gap={0.5}>
					<MainValue>
						<NumberFlow
							style={{ lineHeight: 0.8 }}
							value={text1Value} // Convert to a fraction for percentage display
							locales="en-US"
							format={{
								// style: 'percent',
								minimumFractionDigits: 2,
								maximumFractionDigits: 2
							}}
						/>
					</MainValue>
					<SubValue>
						({renderSubValue(text_2, 'number')} {text_3})
					</SubValue>
				</Box>
				<DateLabel>Since {dayjs(date).format('MMM D YYYY')}</DateLabel>
			</Box>
		</Box>
	)
}

export const WidgetCardRiskRating = ({ text_1, date }: WidgetCard) => {
	return (
		<Box className={styles.widgetCard}>
			<Box className={styles.contentFlex}>
				<Label>Risk Rating</Label>
				<MainValue>
					<AnimatePresence mode="wait" initial={false}>
						<motion.div
							key={`${text_1}-${date}`}
							initial={{ opacity: 0, y: -5 }}
							animate={{ opacity: 1, y: 0 }}
							exit={{ opacity: 0, y: 5 }}
							transition={{ duration: 0.3 }}>
							{text_1}
						</motion.div>
					</AnimatePresence>
				</MainValue>
			</Box>
		</Box>
	)
}
