import { Box } from 'components/layout/box'
import { Text } from 'components/typography/text'
import { Leaderboard as LeaderboardTable } from 'components/Leaderboard'
import { Card } from 'components/Card'

import * as styles from './Leaderboard.css'

export const Leaderboard = () => {
	return (
		<Box className={styles.mainContainer}>
			<Text className={styles.header} variant="body1Bold">
				Leaderboard
			</Text>
			<Card
				style={{
					height: 'inherit',
					padding: '2px 12px',
					marginLeft: '7px',
					marginRight: '7px',
					boxShadow: '-4px -4px 8px 0px rgba(255, 255, 255, 0.04), 4px 4px 8px 0px rgba(0, 0, 0, 0.12)',
					background: '#21242B'
				}}
				bodyStyle={{ borderRadius: '20px' }}>
				<LeaderboardTable />
			</Card>
		</Box>
	)
}
